<template>
   <div class="container">
      <div v-if="$store.state.printScope !== 'full'" class="row justify-content-center" style="border: solid 5px transparent">
         <div 
            v-for="(n, index) in numSlices" 
            :key="index" 
            class="justify-content-center m-1 mt-3 p-1"
            >

            <div class="d-block justify-content-center">
               <div
                  :style="'padding: 5px;' + (index == currentSlice-1 ? 'border: solid 1px blue; border-radius: 10px;' : '')" >
   
                  <img 
                     :src="$store.state.printerBlueSvg"
                     v-if="index < currentSlice" 
                     class="d-block ml-auto mr-auto" 
                     width=30 />
                  <img 
                     :src="$store.state.printerGraySvg"
                     v-else 
                     class="d-block ml-auto mr-auto" 
                     width=30 />
               </div>
            </div>
            <div>
               <span style="font-size: smaller">Print {{n}}</span>
            </div>
         </div>
      </div>

      <div class="container" id="qr-print">
         
         <div class="row justify-content-md-center mb-3">
            <div class="col-12 col-lg-6">
               <div class="text-center mb-1">
                  <div class="text-center qr-num">
                     <h5 v-if="$store.state.printScope !== 'full'"><span class="qr-num-text">QR Code {{ currentSlice }} of {{ numSlices }}</span></h5>
                  </div>
               </div>
            </div>
            <!--END ROW FAQ -->
         </div>
         <div class="row justify-content-md-center mt-5" v-if="$store.state.printScope !== 'full'">
            <div class="col-12 col-lg-6">
               <button
                  type="button"
                  class="btn btn-primary btn-lg btn-block print-btn-1"
                  @click="printSinglePage()"
                  >
                     Print QR Code {{ currentSlice }} of {{ numSlices }}
                  </button>
            </div>
         </div><!--END CONTAINER-->

         <div class="row justify-content-md-center mt-5" v-if="$store.state.printScope === 'full'">
            <div class="col-12 col-lg-6">
               <button
                  type="button"
                  class="btn btn-primary btn-lg btn-block print-btn-1"
                  @click="printAllSlices()"
                  >
                     Print All Pages
                  </button>
            </div>
         </div><!--END CONTAINER-->

         <div class="row justify-content-md-center mt-4" v-if="$store.state.printScope !== 'full' && !isMobile">
            <div class="col-12 col-lg-6">
               <button
                  type="button"
                  class="btn btn-outline-primary btn-lg btn-block"
                  id="createQR"
                  data-toggle="modal"
                  data-target="#copyWarningModal"
                  v-b-modal.copyWarningModal
               >
                  Copy QR Code {{ currentSlice }} of {{ numSlices }}
               </button>
            </div>
         </div><!--END ROW-->

      <div class="row justify-content-md-center mt-5 print-nav-desk">
         <div class="col-12 col-lg-6 d-none d-lg-block text-center">
         <div class="row">
               <div v-if="currentSlice == 1" class="col-6 text-left" >
                  <a href="" class="disable-btn"><i class="bi bi-chevron-left"></i>Back</a>
               </div>
               <div @click="previous($event)" v-else class="col-6 text-left" >
                  <a href=""><i class="bi bi-chevron-left"></i>Back</a>
               </div>

               <div v-if="!isPrintingAll && !didClickPrintOrCopy" @click="$event.preventDefault();" class="col-6 text-right" >
                  <a class="disable-btn" href="">Next<i class="bi bi-chevron-right"></i></a>
               </div>
               <div v-else @click="$event.preventDefault(); next($event)" class="col-6 text-right" >
                  <a href="">Next<i class="bi bi-chevron-right"></i></a>
               </div>
         </div><!--END ROW-->
         </div>
      </div>
         <!--END CONTAINER-->

      <div class="container-fluid print-nav fixed-bottom d-lg-none">
         <div class="row">
            <div class="container">
               <div class="row justify-content-md-center mt-1 mb-1">
                  <div v-if="currentSlice == 1" class=" col-6 col-lg-6 print-nav-btn print-nav-btn-back disable-btn " >
                     <a href="" class="next-button">Back</a>
                  </div>
                  <div @click="$event.preventDefault(); previous()" v-else class="col-6 col-lg-6 print-nav-btn print-nav-btn-back" >
                     <a href="" class="next-button">Back</a>
                  </div>

                  <div v-if="!isPrintingAll && !didClickPrintOrCopy" @click="$event.preventDefault();" class="col-6 col-lg-6 print-nav-btn print-nav-btn-next next-btn" >
                     <a class="disable-btn" href="">Next</a>
                  </div>
                  <div v-else @click="$event.preventDefault(); next()" class="col-6 col-lg-6 print-nav-btn print-nav-btn-next next-btn" >
                     <a href="" class="next-button ariat-next-1">Next</a>
                  </div>
               </div>
            </div>
         </div>
      </div>


 

         <!-- Modal -->
         <b-modal
            class="modal fade"
            id="copyWarningModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            >
            <template #modal-header="{ close }">
               <h5 class="modal-title text-danger" style="margin-left: auto" id="exampleModalLabel">
                  Warning
               </h5>
               <b-button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close()" >
                  <span aria-hidden="true">&times;</span>
               </b-button>
            </template>
            <div class="modal-body text-center">
               <h5>
                  We recommend that you physically print your QR codes and distribute
                  them. Digitally transmitting your QR codes can compromise security.
               </h5>
               <h5>
                  If you do choose to digitally transfer, please review our
                  <a href="#">best practice guide.</a>
               </h5>
            </div>
            <template #modal-footer>
               <button type="button"
                  class="btn btn-primary btn-lg btn-block"
                  @click="$bvModal.hide('copyWarningModal')"
                  data-dismiss="modal"
                  aria-label="Close"
                  style="margin: 10px 10px 10px 10px;"
               >
                  Do Not Copy
               </button>
               <button type="button"
                  class="btn btn-danger btn-lg btn-block"
                  @click="generateSlice(copySVGToClipboard); $bvModal.hide('copyWarningModal')"
                  style="margin: 10px 10px 10px 10px;"
               >
                  Copy
               </button>
            </template>
         </b-modal>
      </div>
   </div>
</template>

<script>
import QRCode from "qrcode";
import base45 from 'base45'

import { pack, unpack } from "@/serializer/binary";

import  { isMobile } from "@/utils/environ"

export default {
   name: "print-qr",
   data() {
      return {
         currentSlice: 1,
         didClickPrintOrCopy: false,
         isPrintingAll: false,
         slicesPrintedSoFar: 0
      };
   },
   computed: {
      isMobile() {
         return isMobile();
      },
      share() {
         return this.$store.state.shares[this.currentSlice - 1];
      },
      numSlices() {
         return this.$store.state.shares.length;
      },
      isDemo() {
         return location.href.includes("/demo");
      },
      demoPrefix() {
         return this.isDemo ? "demo/" : "";
      }
   },
   mounted() {
      this.$store.commit("setIsHidingNavigateAway", true);
      this.didClickPrintOrCopy = false;
   },
   methods: {
      next() {
         // Navigate to next slice
         if (this.currentSlice === this.numSlices || this.$store.state.printScope === "full") {
            console.log("nav to validate page");
            if (this.isDemo) {
               this.$router.push({ path: `/${this.demoPrefix}validate` });            }
            else {
               this.$router.push({ path: `/${this.demoPrefix}validate`, query: { credit: 
                  this.$store.state.activeCreditId } });
            }
         } else {
            this.currentSlice += 1;
            this.didClickPrintOrCopy = false;
         }
      },
      previous() {
         // Navigate to previous slice
         this.currentSlice -= 1;
      },
      copySVGToClipboard(svgText, shareIdx) {
         if (!shareIdx) console.log(shareIdx); // suppress warning

         // Create svg document, rasterize it and copy the generated PNG to the clipboard
         const canvas = document.createElement('canvas');
         const ctx = canvas.getContext("2d");

         // scale
         const imageHeight = 1000;
         canvas.width = (imageHeight / 11) * 8.5;
         canvas.height = imageHeight;
         // add white background
         ctx.fillStyle = "white";
         ctx.fillRect(0, 0, canvas.width, canvas.height);

         // create image
         const img = new Image();
         const blob = new Blob([svgText], { type: 'image/svg+xml' });
         const url = window.URL.createObjectURL(blob);
         img.onload = function () {
            ctx.drawImage(img, 0, 0);
            navigator.permissions.query({name: "clipboard-write"}).then(result => {
               if (result.state == "granted" || result.state == "prompt") {
                  // Convert canvas to blob and write to clipboard
                  canvas.toBlob(
                     function(blob) {
                        console.log("writing to clipboard under permission: " + result.state);
                        const item = new window.ClipboardItem({'image/png': blob});
                        // write to the clipboard
                        navigator.clipboard.write([ item ]).then(function() {
                           // ignore
                           console.log("wrote image to clipboard.");
                        }, function(error) {
                           console.error("unable to write to clipboard. Error: ", error);
                        });
                     },
                     "image/png",
                     1
                  );
               }
               else {
                  console.log("navigator clipboard permissions = " + result.state);
               }
            });
         };
         img.src = url;
         this.didClickPrintOrCopy = true;
      },
      printAllSlices() {
         this.didClickPrintOrCopy = true;
         this.isPrintingAll = true;
         this.slicesPrintedSoFar = 0;

         let placeholders = "";
         for (let sliceIdx = 0; sliceIdx < this.numSlices; ++sliceIdx) {
            let cssStyle = (sliceIdx > 0) ? 'style="page-break-before: always; padding-top: 0.75in;"' : '';
            placeholders += `<div ${cssStyle} id="printSlice${sliceIdx}"></div>`;
         }
         let printPage = document.getElementById('printPage');
         printPage.innerHTML = placeholders;

         for (this.currentSlice = 1; this.currentSlice <= this.numSlices; ++this.currentSlice) {
            this.generateSlice(this.injectSvgPage);
         }
         this.currentSlice = 1; // restore old value
      },
      injectSvgPage(svgText, shareIdx) {
         let sliceHolder = document.getElementById(`printSlice${shareIdx}`);
         sliceHolder.innerHTML = svgText;

         ++this.slicesPrintedSoFar;
         if (this.slicesPrintedSoFar === this.numSlices) {
            window.print();
         }
      },
      printSVG(svgText, shareIdx) {
         if (!shareIdx) console.log(shareIdx); // suppress warning

         // Create svg document and print
         let printPage = document.getElementById('printPage');
         printPage.innerHTML = svgText;
         window.print();
      },
      printSinglePage() {
         this.didClickPrintOrCopy = true;
         this.isPrintingAll = false;
         this.generateSlice(this.printSVG);
      },      
      getIdaPrefix() {
         return "Xecret (TM) -> PRIVATE .ME (R) -> IDA5 -> Encrypted://5";
      },
      getIdaSuffix1() {
         return " => Generated by Xecret (TM)";
      },
      getIdaSuffix2() {
         return " => Generated by app.xecret.io/recover (TM)";
      },
      getWm() {
         return [88, 101, 99, 114, 101, 116, 32, 40, 84, 77, 41];
      },
      isValidSlice(shareText) {
         // Determine if the provided QR Code text is a valid slice, returns result as bool

         let isNewFormat = false;
         let requiredPrefix = this.getIdaPrefix();

         let requiredSuffix1 = this.getIdaSuffix1();
         let requiredSuffix2 = this.getIdaSuffix2();
         let requiredSuffix = "";
         if (shareText.endsWith(requiredSuffix1)) {
            requiredSuffix = requiredSuffix1;
         }
         else {
            requiredSuffix = requiredSuffix2;
         }
         
         let foundPrefix = shareText.slice(0, requiredPrefix.length);
         let foundSuffix = shareText.slice(shareText.length - requiredSuffix.length);
         if (foundPrefix === requiredPrefix && foundSuffix === requiredSuffix) {
            isNewFormat = true;
         }

         console.log("diag1");
         console.log(foundPrefix);
         console.log(foundSuffix);
         console.log(isNewFormat);

         // Try to decode base45 
         let decodedBuffer = null;
         try {
            if (isNewFormat) {
               let truncShareText = shareText.slice(requiredPrefix.length);
               truncShareText = truncShareText.slice(0, truncShareText.length - requiredSuffix.length);
               console.log(truncShareText);
               decodedBuffer = base45.decode(truncShareText);
               let wm = this.getWm();
               for (var i = 0; i < decodedBuffer.length; ++i) {
                  decodedBuffer[i] = decodedBuffer[i] ^ wm[i % wm.length];
               }
            }
            else {
               decodedBuffer = base45.decode(shareText);
            }
         }
         catch(error) {
            console.error(error);
            return false;
         }

         // unpack shares and metadata
         const slice = unpack(decodedBuffer);

         console.log(slice);

         // slice checks
         if (slice.magicNumber !== this.$store.state.magicNumber) {
            return false;
         }
         return true;
      },
      generateSlice(svgProcessor) {
         // Create and encode slice, write slice to QR code SVG, populate print document SVG and call svgProcessor
         const slice = {
            magicNumber: this.$store.state.magicNumber,
            n: this.$store.state.createShareCount,
            k: this.$store.state.createShareThreshold,
            shareId: this.share.ShareIdx,
            UUID: this.$store.state.shareUUID,
            payload: this.share.SliceBytes
         }
         let packedSlice = pack(slice);
         let wm = this.getWm();
         for (var i = 0; i < packedSlice.length; ++i) {
            packedSlice[i] = packedSlice[i] ^ wm[i % wm.length];
         }

         const config = {
            errorCorrectionLevel: "L",
            mode: "alphanumeric",
            margin: 0,
            slice: slice,
            name: this.$store.state.shareName
         }

         let base45Str = base45.encode(packedSlice);

         let qrDataString = 
            this.getIdaPrefix() + base45Str + this.getIdaSuffix2();

         if (this.isValidSlice(qrDataString)) {
            console.log("valid slice");
         }
         else {
            console.log("invalid slice");
         }


         let sliceIdx = this.share.ShareIdx;

         // We are populating the SVG as text since it is generated by Inkscape and may change over time.
         // If the SVG changes, this code will still work without needing updates as opposed to needing to maintaining a Vue template.
         const that = this;
         QRCode.toString(qrDataString, config, function (err, qrSVGString) {
            let svgText = that.$store.state.pageTemplateSvgText;
            const viewboxRegex = /viewBox="(-?\d+ ?){4}"/;

            // grab the last number int the viewboxRegex
            const newHeight = parseInt(qrSVGString.match(viewboxRegex)[1]);
            const x = -37;
            const y = -50.5;
            const sizeNum = 135;
            const sizeDenom = 90;
            const viewboxText = `viewBox="${x * newHeight / sizeDenom} ${y * newHeight / sizeDenom} ${sizeNum * newHeight / sizeDenom} ${sizeNum * newHeight / sizeDenom}"`;

            // NOTE: these modifications are required for the qr code to be drawn correctly in the svg document
            // update viewBox values
            qrSVGString = qrSVGString.replace(viewboxRegex, viewboxText);
            // remove background fill
            qrSVGString = qrSVGString.replace(/fill="#\w+"/, 'fill="none"');

            const colorNames = ["Black", "Blue", "Red", "Burgundy", "Green", "Purple", "Orange", "Navy", "Brown", "Pink", "Charcoal"];
            const colorHashVals = ["#000000", "#0010EB", "#FF0000", "#940128", "#279401", "#5C04C1", "#EC7E13", "#003F77", "#89362B", "#EE356E", "#707070"];

            const uuidString =
               slice.UUID[0].toString() +
               slice.UUID[1].toString() +
               slice.UUID[2].toString() +
               slice.UUID[3].toString();

            // Replace placeholder values
            svgText = svgText.replace("$SliceDesc$", `${config.name}`);
            svgText = svgText.replace("$SetId$", uuidString);
            svgText = svgText.replace("$Color$", colorNames[slice.shareId]);
            svgText = svgText.replace("#ff69b4", colorHashVals[slice.shareId]);
            svgText = svgText.replace("2 of 3", `${slice.shareId + 1} of ${slice.n}`);
            svgText = svgText.replace("$InsertSvgContent$", qrSVGString);

            // Print or copy SVG to clipboard
            svgProcessor(svgText, sliceIdx);
         });
      },
   },
};
</script>
